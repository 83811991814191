/* SearchForm.css */

/* Style the search form container */
.search-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Style form fields */
.form-control {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
}

/* Style the "Search" button */
.search-button {
    background-color: black;
    color: #000;
    border: 3px solid #000;
    padding: 10px 20px;
    cursor: pointer;

    font-family: inherit;
    font-size: inherit;
    background: none;
    cursor: pointer;
    padding: 15px 40px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.search-button:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

/* Style the "Search" button on hover */
.search-button:hover {
    background-color: #000;
    color: #fff;
}

.btn-1c:after {
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
}

.btn-1c:hover,
.btn-1c:active {
    color: #fff;
}

.btn-1c:hover:after,
.btn-1c:active:after {
    width: 100%;
}

/* Style the select dropdown for "Guests" */
.form-select {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
}


/* Styling for the suggestions dropdown */
.suggestions {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    width: 400px;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
}

.suggestions ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.suggestions li {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align: start;
}

.suggestions li:hover {
    background-color: #f1f1f1;
}

.inner-btn {
  position: absolute;
  width: 10px;
  font-weight: 550;
  border: none;
  background-color: transparent;
  line-height: 35px;
  left: calc(100% - 30px);
  z-index: 99999;
  color: red;
}

.inner-btn:hover {
  color: red;
}