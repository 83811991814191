@media only screen and (max-width: 768px) {
    .image-gallery-slide img {
        width: 100%;
        height: 170px;
    }
}

.image-gallery-content.fullscreen .image-gallery-slide img {
    height: auto;
        max-height: 80vh;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image{
    height: 60px;
    object-fit: cover; 
}

.image-gallery-slide img {
    width: 100%;
    height: 450px;
}

.image-gallery-slide .image-gallery-image {
    object-fit: cover;
    object-position: center center;
}



.room-list{
    /* max-height: 400px; */
}

.roomimg{
    height: 150px !important;
    width: 200px !important;
    object-fit: cover;
}

.booking-form-container {
  display:block;
  flex-wrap: wrap;
}

.sticky-top {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 20px; /* Adjust this value as needed */
}

.accordion-button{
padding: 5px 10px;
}
